import { CreditLinePartnerType } from "@daytrip/legacy-enums";
import type { BizDevContract, SimpleUser } from "@daytrip/legacy-models";
import { HostAgency, Country } from "@daytrip/legacy-models";
import {
    transformSimpleUsersToSelectOptions,
    transformValidationErrorsToArrayString,
} from "@daytrip/legacy-transformers";
import { OrderDataForOrdersPage } from "@legacy/dataTransferObjects/OrderDataForOrdersPage";
import { SimpleTravelAgent } from "@legacy/domain/SimpleTravelAgent";
import { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import { RetrieveSettlementsOptions } from "@legacy/options/RetrieveSettlementsOptions";
import { RetrieveTravelAgentsOptions } from "@legacy/options/RetrieveTravelAgentsOptions";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";
import { Option } from "react-select-legacy";

import { CommissionSettlement } from "../../../../api/src/domain/host-agency/models/commission-settlement.model";
import { HostAgencyChangeLog } from "../../../../legacy/source/models/HostAgencyChangeLog";
import type { CreditLineStore } from "../../components/CreditLine/CreditLineStore";
import type { StoreManager } from "../../container";
import { container, stores } from "../../container";
import { globalManagementLogger } from "../../global-logger";
import { PageStore } from "../../stores/PageStore";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";

import type { HostAgencyPageRouter } from "./HostAgencyPageRouter";

@autobind
export class HostAgencyPageStore extends PageStore<HostAgencyPageRouter, {}> {
    @observable
    public hostAgency: HostAgency | undefined;

    @observable
    public editedHostAgency: HostAgency | undefined;

    @observable
    public countries: Country[] | undefined;

    @observable
    public editedHostAgencyValidationErrors: Array<ValidationError> = [];

    @observable
    public orders?: PaginatedDataStore<RetrieveOrdersOptions, OrderDataForOrdersPage>;

    @observable
    public travelAgents?: PaginatedDataStore<RetrieveTravelAgentsOptions, SimpleTravelAgent>;

    @observable
    public settlements?: PaginatedDataStore<RetrieveSettlementsOptions, CommissionSettlement>;

    @observable
    public ownerIdOption: Option | undefined;

    private validationMessages: Record<string, string> = {};

    @observable
    public isCreditLineDataFetched: boolean = false;

    @observable
    public creditLineStore: CreditLineStore | undefined;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public goToCreateOrder() {
        if (!this.hostAgency?.ownerId) {
            return;
        }
        const userId = this.hostAgency?.ownerId;
        this.pageRouter.openCreateOrder(userId);
    }

    @action
    public async fetchContent() {
        const hostAgencyId = this.pageRouter.id;

        if (hostAgencyId) {
            const hostAgency = await this.rpcClient.hostAgency.retrieveHostAgency(hostAgencyId);
            this.hostAgency = hostAgency;

            this.creditLineStore = container.get<StoreManager<CreditLineStore>>(stores.creditLineStore).getStore();
            await this.creditLineStore.init({ partnerId: hostAgencyId, partnerType: CreditLinePartnerType.HostAgency });
            this.isCreditLineDataFetched = true;

            const promises = [];
            if (hostAgency.ownerId) {
                promises.push(this.getHostAgencyOwner(hostAgency));
            }

            await Promise.all([
                ...promises,
                this.fetchTravelAgents(hostAgency._id),
                this.fetchOrders(hostAgency._id),
                this.fetchSettlements(hostAgency._id),
            ]);
        } else {
            this.hostAgency = classToPlain(new HostAgency()) as HostAgency;
            this.hostAgencyEdit();
        }

        this.fetchCountries();
    }

    private async getHostAgencyOwner(hostAgency: HostAgency): Promise<void> {
        const owner = await this.rpcClient.user.retrieveUser(hostAgency.ownerId!);
        this.ownerIdOption = {
            label: `${owner.firstName} ${owner.lastName} <${owner.email}>`,
            value: owner._id,
        };
    }

    @action
    public fetchOrders(hostAgencyId: string) {
        if (!this.orders) {
            this.orders = new PaginatedDataStore(
                OrderDataForOrdersPage,
                RetrieveOrdersOptions,
                this.rpcClient.order.retrieveOrdersDataForOrdersPage,
                this.rpcClient.order.retrieveOrdersCount,
                this.pageRouter,
                "orders_",
                {
                    skip: 1,
                    limit: 10,
                    hostAgencyIds: [hostAgencyId],
                    sortBy: "departureAt",
                    sortDirection: -1,
                },
            );
        } else {
            this.orders.fetch();
        }
    }

    @action
    public setHostAgencyProperty<K extends keyof HostAgency>(key: K, value: HostAgency[K]) {
        (this.editedHostAgency as HostAgency)[key] = value;
    }

    @action
    public fetchTravelAgents(hostAgencyId: string) {
        if (!this.travelAgents) {
            this.travelAgents = new PaginatedDataStore(
                SimpleTravelAgent,
                RetrieveTravelAgentsOptions,
                this.rpcClient.travelAgent.retrieveTravelAgents,
                this.rpcClient.travelAgent.retrieveTravelAgentsCount,
                this.pageRouter,
                "travelAgents_",
                {
                    skip: 1,
                    limit: 10,
                    hostAgencyIds: [hostAgencyId],
                    sortBy: "createdAt",
                    sortDirection: -1,
                },
            );
        } else {
            this.travelAgents.fetch();
        }
    }

    @action
    public async fetchSettlements(hostAgencyId: string) {
        if (!this.authenticationStore.hasPermissions("CommissionSettlements:Read")) {
            return;
        }

        if (!this.settlements) {
            this.settlements = new PaginatedDataStore(
                CommissionSettlement,
                RetrieveSettlementsOptions,
                this.rpcClient.commissionSettlement.getCommissionSettlements,
                this.rpcClient.commissionSettlement.getCommissionSettlementsCount,
                this.pageRouter,
                "settlements_",
                {
                    skip: 0,
                    limit: 10,
                    hostAgencyId,
                    sortBy: "startDate",
                    sortDirection: -1,
                },
            );
        } else {
            this.settlements.fetch();
        }
    }

    @action
    public async approveTravelAgent(id: string) {
        await this.rpcClient.travelAgent.approveTravelAgent(id);
        this.fetchContent();
    }

    @action
    public async declineTravelAgent(id: string, reason: string) {
        await this.rpcClient.travelAgent.declineTravelAgent(id, reason);
        this.fetchContent();
    }

    @action
    public hostAgencyEdit() {
        this.editedHostAgency = toJS(this.hostAgency);
    }

    @action
    public setOwnerIdOption(option: Option) {
        this.ownerIdOption = option;
    }

    @action
    public hostAgencyEditCancelOrGoBack() {
        if (!this.pageRouter.id) {
            window.history.back();
        } else {
            this.editedHostAgency = undefined;
        }
    }

    private async hostAgencyValidate() {
        this.editedHostAgencyValidationErrors = await validate(plainToClass(HostAgency, toJS(this.editedHostAgency)), {
            skipMissingProperties: true,
        });
    }

    @action
    public async hostAgencyEditSave() {
        await this.hostAgencyValidate();

        if (this.editedHostAgencyValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(
                this.editedHostAgencyValidationErrors,
            ).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (this.hostAgency && this.editedHostAgency) {
            try {
                await this.rpcClient.hostAgency.updateHostAgency(this.editedHostAgency);
                this.hostAgency = toJS(this.editedHostAgency);
                this.editedHostAgency = undefined;

                this.fetchTravelAgents(this.hostAgency?._id);
                this.loadChangeLogs();
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
                globalManagementLogger.error(error);
            }
        } else if (this.editedHostAgency) {
            try {
                this.editedHostAgency._id = await this.rpcClient.hostAgency.createHostAgency(this.editedHostAgency);
                this.pageRouter.openHostAgency(this.editedHostAgency._id);
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
                globalManagementLogger.error(error);
            }
        }
    }

    @action
    public async hostAgencyAddContract(contract: BizDevContract) {
        if (!this.hostAgency) {
            return;
        }

        const originalContracts = this.hostAgency.contracts ? [...this.hostAgency.contracts] : [];

        try {
            this.hostAgency.contracts = [...originalContracts, contract];
            await this.rpcClient.hostAgency.updateHostAgency(this.hostAgency);
        } catch (error: any) {
            this.hostAgency.contracts = originalContracts;
            alert(`Oh, something is wrong. :\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    @action
    public async deleteHostAgency() {
        try {
            if (this.creditLineStore?.creditLine) {
                await this.creditLineStore.deleteCreditLine();
            }
            await this.rpcClient.hostAgency.deleteHostAgency((this.hostAgency as HostAgency)._id);
            this.pageRouter.openHostAgencies();
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    @action
    public async loadUserOptions(searchString: string) {
        if (!searchString) {
            return { options: [] };
        }

        const userOptions = {
            isDriver: false,
            isCompanyDriver: false,
            isDriversCompany: false,
            searchString,
        };

        const users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);

        return {
            options: transformSimpleUsersToSelectOptions(users),
        };
    }

    @action
    public async createHostAgency() {
        if (!this.editedHostAgency) {
            return;
        }

        await this.hostAgencyValidate();

        if (this.editedHostAgencyValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(
                this.editedHostAgencyValidationErrors,
            ).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        try {
            const hostAgencyId = await this.rpcClient.hostAgency.createHostAgency(this.editedHostAgency as HostAgency);
            this.hostAgency = this.editedHostAgency;
            this.editedHostAgency = undefined;
            this.pageRouter.openHostAgency(hostAgencyId);
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    private setFieldValidationMessage(propertyName: string): void {
        const validationErrors = this.editedHostAgencyValidationErrors.find((ve) => ve.property === propertyName);
        if (!validationErrors) {
            return;
        }

        this.validationMessages[propertyName] = JSON.stringify(validationErrors.constraints);
    }

    private setFieldValidationMessages() {
        this.validationMessages = {};
        this.setFieldValidationMessage("_id");
        this.setFieldValidationMessage("url");
        this.setFieldValidationMessage("name");
        this.setFieldValidationMessage("commissionPercentage");
        this.setFieldValidationMessage("discountCoefficient");
        this.setFieldValidationMessage("partnerBookingAvailable");
        this.setFieldValidationMessage("defaultCountryIso");
    }

    @computed
    public get fieldValidationMessages(): Record<string, string> {
        this.setFieldValidationMessages();
        return this.validationMessages;
    }

    private async fetchCountries() {
        const countries = await this.rpcClient.content.retrieveCountries({});
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    public isDataFetched(): this is HostAgencyPageStore & HostAgencyPageStoreDataFetched {
        return !!(this.hostAgency || this.editedHostAgency);
    }

    @observable
    public changeLogs?: HostAgencyChangeLog;

    @observable
    public changedByUsers?: Array<SimpleUser>;

    @observable
    public noChangesFound?: boolean;

    @action
    public async loadChangeLogs(): Promise<void> {
        if (!this.hostAgency) {
            this.noChangesFound = true;
            return;
        }

        this.changeLogs = plainToClass(
            HostAgencyChangeLog,
            await this.rpcClient.hostAgency.getHostAgencyChangeLogs(this.hostAgency._id),
        );

        if (this.changeLogs !== undefined) {
            await this.loadChangedByUsers(
                this.changeLogs.logs.filter((l) => l.changedByUserId).map((l) => l.changedByUserId) as Array<string>,
            );
        } else {
            this.noChangesFound = true;
        }
    }

    @action
    public async loadChangedByUsers(userIds: Array<string>): Promise<void> {
        this.changedByUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds });
    }
}

export interface HostAgencyPageStoreDataFetched {
    hostAgency: HostAgency;
}
