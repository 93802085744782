import { ApiPartnerModel as ApiPartner } from "@daytrip/api";
import { CreditLinePartnerType } from "@daytrip/legacy-enums";
import { BizDevContract, SimpleUser } from "@daytrip/legacy-models";
import { transformSimpleUsersToSelectOptions } from "@daytrip/legacy-transformers";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { OrderDataForOrdersPage } from "@legacy/dataTransferObjects/OrderDataForOrdersPage";
import type { Country } from "@legacy/models/Country";
import { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";
import { Option } from "react-select-legacy";

import { ApiPartnerChangeLog } from "../../../../packages/legacy-models/src/models/ApiPartnerChangeLog";
import type { CreditLineStore } from "../../components/CreditLine/CreditLineStore";
import type { StoreManager } from "../../container";
import { container, stores } from "../../container";
import { globalManagementLogger } from "../../global-logger";
import { PageStore } from "../../stores/PageStore";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";
import { validatePhoneNumberWithAlert } from "../../utils/validateUserPhoneNumber";

import type { ApiPartnerPageRouter } from "./ApiPartnerPageRouter";

@autobind
export class ApiPartnerPageStore extends PageStore<ApiPartnerPageRouter, {}> {
    @observable
    public apiPartner: ApiPartner | undefined;

    @observable
    public editedApiPartner: ApiPartner | undefined;

    @observable
    public countries: Country[] | undefined;

    @observable
    public orders?: PaginatedDataStore<RetrieveOrdersOptions, OrderDataForOrdersPage>;

    @observable
    public editedApiPartnerValidationErrors: Array<ValidationError> = [];

    @observable
    public ownerIdOption: Option | undefined;

    private validationMessages: Record<string, string> = {};

    @observable
    public isCreditLineDataFetched: boolean = false;

    @observable
    public creditLineStore: CreditLineStore | undefined;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        const apiPartnerId = this.pageRouter.id;

        if (apiPartnerId) {
            const apiPartner = await this.rpcClient.apiPartner.getApiPartnerById(apiPartnerId);
            this.apiPartner = apiPartner;

            this.creditLineStore = container.get<StoreManager<CreditLineStore>>(stores.creditLineStore).getStore();
            await this.creditLineStore.init({ partnerId: apiPartnerId, partnerType: CreditLinePartnerType.ApiPartner });
            this.isCreditLineDataFetched = true;

            const promises = [];
            if (apiPartner.ownerId) {
                promises.push(this.getApiPartnerOwner(apiPartner));
                promises.push(this.fetchOrders(apiPartnerId));
            }

            await Promise.all([...promises]);
        } else {
            this.apiPartner = classToPlain(new ApiPartner()) as ApiPartner;
            this.apiPartnerEdit();
        }
        this.fetchCountries();
    }

    private async getApiPartnerOwner(apiPartner: ApiPartner): Promise<void> {
        const owner = await this.rpcClient.user.retrieveUser(apiPartner.ownerId, true);
        this.ownerIdOption = {
            label: `${owner.firstName} ${owner.lastName} <${owner.email}>`,
            value: owner._id,
        };
    }

    @action
    public fetchOrders(apiPartnerId: string) {
        if (!this.orders) {
            this.orders = new PaginatedDataStore(
                OrderDataForOrdersPage,
                RetrieveOrdersOptions,
                this.rpcClient.order.retrieveOrdersDataForOrdersPage,
                this.rpcClient.order.retrieveOrdersCount,
                this.pageRouter,
                "orders_",
                {
                    skip: 1,
                    limit: 30,
                    apiPartnerIds: [apiPartnerId],
                    sortBy: "departureAt",
                    sortDirection: -1,
                },
            );
        } else {
            this.orders.fetch();
        }
    }

    @action
    public goToCreateOrder() {
        if (!this.apiPartner?.ownerId) {
            return;
        }

        const userId = this.apiPartner?.ownerId;
        this.pageRouter.openCreateOrder(userId);
    }

    @action
    public setApiPartnerProperty<K extends keyof ApiPartner>(key: K, value: ApiPartner[K]) {
        (this.editedApiPartner as ApiPartner)[key] = value;
    }

    @action
    public apiPartnerEdit() {
        this.editedApiPartner = toJS(this.apiPartner);
    }

    @action
    public setOwnerIdOption(option: Option) {
        this.ownerIdOption = option;
    }

    @action
    public async setCountryId(value: string | undefined) {
        (this.editedApiPartner as ApiPartner).countryId = value || "";
    }

    @action
    public apiPartnerEditCancelOrGoBack() {
        if (!this.pageRouter.id) {
            window.history.back();
        } else {
            this.editedApiPartner = undefined;
        }
    }

    private async apiPartnerValidate() {
        console.log("apiPartnerValidate", this.editedApiPartner);

        this.editedApiPartnerValidationErrors = await validate(plainToClass(ApiPartner, toJS(this.editedApiPartner)), {
            skipMissingProperties: true,
        });
    }

    @action
    public async apiPartnerEditSave() {
        await this.apiPartnerValidate();

        if (this.editedApiPartnerValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(
                this.editedApiPartnerValidationErrors,
            ).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (!validatePhoneNumberWithAlert((this.editedApiPartner ?? this.apiPartner!).phoneNumber!, false)) {
            return;
        }

        if (this.apiPartner && this.editedApiPartner) {
            try {
                await this.rpcClient.apiPartner.updateApiPartner(this.apiPartner._id, this.editedApiPartner);
                this.apiPartner = toJS(this.editedApiPartner);
                this.editedApiPartner = undefined;
                this.loadChangeLogs();
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
                globalManagementLogger.error(error);
            }
        } else if (this.editedApiPartner) {
            try {
                this.editedApiPartner = await this.rpcClient.apiPartner.createApiPartner(this.editedApiPartner);
                this.pageRouter.openApiPartner(this.editedApiPartner._id);
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
                globalManagementLogger.error(error);
            }
        }
    }

    @action
    public async apiPartnerAddContract(contract: BizDevContract) {
        if (!this.apiPartner) {
            return;
        }

        const originalContracts = this.apiPartner.contracts ? [...this.apiPartner.contracts] : [];

        try {
            this.apiPartner.contracts = [...originalContracts, contract];
            await this.rpcClient.apiPartner.updateApiPartner(this.apiPartner._id, this.apiPartner);
        } catch (error: any) {
            this.apiPartner.contracts = originalContracts;
            alert(`Oh, something is wrong. :\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    @action
    public async deleteApiPartner() {
        try {
            if (this.creditLineStore?.creditLine) {
                await this.creditLineStore.deleteCreditLine();
            }
            await this.rpcClient.apiPartner.deleteApiPartner((this.apiPartner as ApiPartner)._id);
            this.pageRouter.openApiPartners();
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    @action
    public async loadUserOptions(searchString: string) {
        if (!searchString) {
            return { options: [] };
        }

        const userOptions = {
            isDriver: false,
            isCompanyDriver: false,
            isDriversCompany: false,
            searchString,
        };

        const users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);

        return {
            options: transformSimpleUsersToSelectOptions(users),
        };
    }

    @action
    public async createApiPartner() {
        if (!this.editedApiPartner) {
            return;
        }

        await this.apiPartnerValidate();

        if (this.editedApiPartnerValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(
                this.editedApiPartnerValidationErrors,
            ).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (!validatePhoneNumberWithAlert(this.editedApiPartner?.phoneNumber ?? "", false)) {
            return;
        }

        try {
            const apiPartner = await this.rpcClient.apiPartner.createApiPartner(this.editedApiPartner);
            this.apiPartner = this.editedApiPartner;
            this.editedApiPartner = undefined;
            this.pageRouter.openApiPartner(apiPartner._id);
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    private setFieldValidationMessage(propertyName: string): void {
        const validationErrors = this.editedApiPartnerValidationErrors.find((ve) => ve.property === propertyName);
        if (!validationErrors) {
            return;
        }

        this.validationMessages[propertyName] = JSON.stringify(validationErrors.constraints);
    }

    @observable
    public changeLogs?: ApiPartnerChangeLog;

    @observable
    public changedByUsers?: Array<SimpleUser>;

    @observable
    public noChangesFound?: boolean;

    @action
    public async loadChangeLogs(): Promise<void> {
        if (!this.apiPartner) {
            this.noChangesFound = true;
            return;
        }

        this.changeLogs = plainToClass(
            ApiPartnerChangeLog,
            await this.rpcClient.apiPartner.getApiPartnerChangeLogs(this.apiPartner._id),
        );

        if (this.changeLogs !== undefined) {
            await this.loadChangedByUsers(
                this.changeLogs.logs.filter((l) => l.changedByUserId).map((l) => l.changedByUserId) as Array<string>,
            );
        } else {
            this.noChangesFound = true;
        }
    }

    @action
    public async loadChangedByUsers(userIds: Array<string>): Promise<void> {
        this.changedByUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds });
    }

    private setFieldValidationMessages() {
        this.validationMessages = {};
        this.setFieldValidationMessage("_id");
        this.setFieldValidationMessage("name");
        this.setFieldValidationMessage("email");
        this.setFieldValidationMessage("emailsEnabled");
        this.setFieldValidationMessage("apiKey");
        this.setFieldValidationMessage("phoneNumber");
        this.setFieldValidationMessage("countryId");
        this.setFieldValidationMessage("discountPercentage");
    }

    @computed
    public get fieldValidationMessages(): Record<string, string> {
        this.setFieldValidationMessages();
        return this.validationMessages;
    }

    private async fetchCountries() {
        const countries = await this.rpcClient.content.retrieveCountries({});
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    public isDataFetched(): this is ApiPartnerPageStore & ApiPartnerPageStoreDataFetched {
        return !!(this.apiPartner || this.editedApiPartner);
    }
}

export interface ApiPartnerPageStoreDataFetched {
    apiPartner: ApiPartner;
}
